<template>
	<v-app>
		<app-bar v-if="AppStore.started_app"></app-bar>
		<v-main>
			<v-slide-x-transition mode="out-in">
				<router-view></router-view>
			</v-slide-x-transition>
			<v-snackbar
				v-model="snackbar"
				:timeout="timeout"
				:color="color"
			>
				<div v-html="text"></div>

				<template v-slot:action="{ attrs }">
					<v-btn
						color="blue"
						icon
						v-bind="attrs"
						@click="snackbar = false"
					>
						X
					</v-btn>
				</template>
			</v-snackbar>
		</v-main>
		<v-footer app
		          v-if="AppStore.started_app && $route.name === 'mb-3-home' && !_.isEmpty(AppStore.data.general.client_logo)"
		          padless
		          :color="!AppStore.data.general.dark_mode ? 'white' : ''"
		>
			<v-container fluid>
				<v-row id="logo-client">
					<v-col class="">
						<v-img
							max-height="220"
							max-width="165"
							id="app-bar-logo"
							style="display:block;margin: 0 auto;"
							:src="AppStore.data.general.client_logo"
						></v-img>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>

		<v-footer app v-if="AppStore.started_app && $route.name === 'mb-3-questions'" padless id="footer" :color="!AppStore.data.general.dark_mode ? 'white' : ''">
			<v-container fluid>
				<v-row id="cgu-row">
					<v-col class="text-center pa-1" id="cgu">
						<div v-html="AppStore.data.configuration[AppStore.locale].information_txt"></div>
						<span v-html="AppStore.data.configuration[AppStore.locale].cgu_txt"></span> {{ ' ' }}
						<span v-html="AppStore.data.configuration[AppStore.locale].cgu_btn"
						      @click="AppStore.toggleCGU = !AppStore.toggleCGU" class="font-weight-bold"></span>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>
	</v-app>
</template>

<script>
import AppStore from "../../../stores/AppStore";
import AppBar from "../../../components/mb/AppBar.vue";
import _ from "lodash";
import {EventBus} from "../../../services/Request";
import ModelLogic from "../../../logics/ModelLogic";
import moment from "moment";
import Helpers from "../../../services/Helpers";

export default {
	name: 'Layout',

	mounted() {
		if (!_.includes(['mb-3-home', 'mb-3-token'], this.$route.name) && !AppStore.started_app) {
			console.log('GO HOME FROM LAYOUT')
			this.$router.push({name: 'mb-3-home'})
		}

		EventBus.$on('show-snackbar', (data) => {
			this.snackbar = false; //reset todo need stacked snack
			this.text = data.text || 'No information';
			this.timeout = data.timeout || 2000;
			this.color = data.color || 'green';
			this.snackbar = true;
		});
	},

	created() {
		EventBus.$on('getApp', (data) => {
			this.getApp(data)
		});

		EventBus.$on('storeRating', () => {
			this.sendRating()
		});

		EventBus.$on('setQuestionAnswer', (data) => {
			this.setQuestionAnswer(data)
		});
	},

	components: {
		AppBar,
	},

	data: () => ({
		AppStore,
		snackbar: false,
		text: '',
		timeout: 2000,
		color: 'primary',
		env: process.env.VUE_APP_ENV,
		appDomain: process.env.VUE_APP_APPS_HOST,
		checkingID: null,
		timeoutID: null,
	}),

	computed: {
		Helpers() {
			return Helpers
		},
		_() {
			return _;
		},
	},

	watch: {
		$route(to, from) {
			const pathTo = to.name.split('-').slice(0, 3)
			const pathFrom = from.name.split('-').slice(0, 3)
			if (pathTo[2] === 'appreciation' && pathFrom[2] === 'timeline') {
				if (AppStore.mobileAlreadyPostRating) {
					this.$router.push({name: 'mb-3-home', query: AppStore.query_params})
				}
			}

			if (pathTo[2] === 'home' && pathFrom[2] === 'timeline') {
				this.resetRating()
			}

			if (pathTo[2] === 'home') {
				this.setQuestionAnswerFromUrlParams()
			}
		}
	},

	methods: {
		resetRating() {
			AppStore.rating = _.cloneDeep(AppStore.defaultRating)
		},

		sendRating() {
			if (AppStore.is_connected) {
				AppStore.rating.posted_at = moment().utc().format("DD/MM/YYYY HH:mm:ss")
				AppStore.rating.device_id = AppStore.data.device ? AppStore.data.device.id : null
				AppStore.rating.source = AppStore.data.source
				AppStore.rating.app_id = AppStore.data.id
				AppStore.rating.locale = AppStore.locale
				AppStore.rating.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				let ratingToSend = AppStore.rating
				ratingToSend.uuid = Helpers.uuidv4()

				ModelLogic
					.store('ratings/post/liz', ratingToSend)
					.then(() => {
						if (AppStore.data.general.post_single_rating) {
							AppStore.mobileAlreadyPostRating = true;
							this.$cookie.set(AppStore.data.slug, moment().add(AppStore.data.general.cookie_expiration, 'hours'), { expires: new Date(moment().add(AppStore.data.general.cookie_expiration, 'hours')) });
						}
					})
					.catch((error) => {
						// if (error.response.data.errors) {
						//     this.$refs.observer.setErrors(error.response.data.errors);
						// }
						console.error(error)
					})
			}
		},

		async getApp(data) {
			if (AppStore.is_connected) {
				await ModelLogic
					.getOne('apps', data.type + '/' + data.model + '/' + data.slug, {
						// with: ['unit.ratings.data', 'unit.ratings.consumer', 'unit.ratings.contents', 'unit.ratings.answers', 'unit.statistics']
					})
					.then((apps) => {
						AppStore.data = apps
						this.$vuetify.theme.dark = AppStore.data.general.dark_mode
						if (AppStore.data.general.font_override) {
							document.head.insertAdjacentHTML("beforeend", '<link href="' + AppStore.data.general.font_override + '" rel="stylesheet"></link>')
						}
						document.head.insertAdjacentHTML("beforeend", '<style>' + AppStore.data.general.css_override + '</style>')
						AppStore.started_app = true

						if (!data.noredirect && this.$route.name !== 'mb-3-home') {
							this.$router.push({
								name: 'mb-3-home',
								params: {slug: data.slug},
								query: data.params
							})
						}
					})
					.catch((err) => {
						console.error(err)
						AppStore.app_is_loading = false;
						EventBus.$emit('show-snackbar', {
							text: 'Application non trouvée',
							timeout: 10000,
							color: 'error'
						})
					}).finally(() => {
						if (AppStore.started_app) {
							this.setHiddenQuestions();
							this.setQuestions()
						}

						AppStore.mobileAlreadyPostRating = !!(AppStore.data.general.post_single_rating && this.$cookie.get(data.slug));
						AppStore.app_is_loading = false;
						AppStore.is_first_launch = false;

					})

			} else {
				console.log("Pas de connexion internet (getApp)")
			}
		},

		async setQuestions() {
			AppStore.bookmarkedQuestions = await this.filterBookmarkedQuestions()
			AppStore.conditionedQuestion = await this.filterConditionedQuestion()
			AppStore.conditionalQuestions = await this.filterConditionalQuestions()
			AppStore.data.questions = await this.filterQuestions();
			AppStore.questions_by_page = _.groupBy(_.orderBy(AppStore.data.questions, ['is_bookmarked'], ['desc']), 'page');
			await this.setQuestionAnswerFromUrlParams()
			EventBus.$emit('started-app')
		},

		async filterQuestions() {
			return _.filter(AppStore.data.questions, function (q) {
				q.must_be_hide = q.is_hidden || q.conditional_question_id;
				return q
			});
		},

		async filterConditionedQuestion() {
			return _.filter(AppStore.data.questions, (q) => {
				if (!_.isEmpty(q.conditional_question_id)) {
					return q
				}
			});
		},

		async filterConditionalQuestions() {
			return _.map(_.filter(AppStore.data.questions, (q) => {
				if (!_.isEmpty(q.conditional_question_id)) {
					return q.conditional_question_id
				}
			}), 'conditional_question_id.id');
		},

		async filterBookmarkedQuestions() {
			return _.groupBy(_.filter(AppStore.data.questions, (q) => {
				return q.is_bookmarked
			}), 'page')
		},

		async setQuestionAnswerFromUrlParams() {
			if (!_.isEmpty(this.$route.query)) {
				AppStore.query_params = this.$route.query;
				_.forEach(Object.entries(AppStore.query_params), (param, index) => {
					_.forEach(AppStore.data.questions, (questions, index) => {
						if (questions.short_name === param[0]) {
							this.$set(AppStore.rating.questions_answers, questions.id, {
								question_id: questions.id,
								content: param[1]
							})
						}
					});
				});
			}
		},

		async setQuestionAnswer(data) {
			// console.log("setQuestionAnswer")
			await this.setAnswer(data)
			this.checkIfConditonal(data)
		},

		checkIfConditonal (data) {
			// console.log("checkifConditonal")
			// console.log(AppStore.conditionalQuestions)//only ids
			if (AppStore.conditionalQuestions.length && _.includes(AppStore.conditionalQuestions, data.question.id)) {
				// console.log("question id")
				// console.log(data.question)
				// console.log("filtered questions conditional")
				// console.log(_.filter(
				// 	AppStore.conditionedQuestion, function (q) {
				// 		return q.conditional_question_id.id === data.question.id;
				// 	}
				// ))//QUESTIONS OBJECT
				// console.log("all condition questions")
				// console.log(AppStore.conditionedQuestion)//QUESTIONS OBJECT
				_.each(_.filter(
					AppStore.conditionedQuestion, function (q) {
						return q.conditional_question_id.id === data.question.id;
					}), (conditionedQuestion, i) => {


					const index = _.findIndex(AppStore.questions_by_page[conditionedQuestion.page], (q) => {
						return q.id === conditionedQuestion.id;
					}, 0)
					if (index !== -1) {
						if (data.question.format === 'select' && Array.isArray(data.inputValue)) {
							// console.log(conditionedQuestion.conditional_answer.split('~'))
							// console.log(data.inputValue)
							// console.log(data.inputValue.some(r=> conditionedQuestion.conditional_answer.split('~').includes(r)))
							AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide = !data.inputValue.some(r=> conditionedQuestion.conditional_answer.split('~').includes(r))
							// console.log('if multiple answer')
							// EventBus.$emit('refreshQuestions')

						} else if (conditionedQuestion.is_conditional_answer_regex) {
							const regex = new RegExp(conditionedQuestion.conditional_answer);
							// if(AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide != regex.test(data.inputValue) ? false : true) {
							// 	EventBus.$emit('refreshQuestions')
							//
							// }
							AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide = regex.test(data.inputValue) ? false : true
							// console.log(conditionedQuestion.page + ' ')
							// console.log(conditionedQuestion.name + ' ')
							// console.log(regex.test(data.inputValue) ? false : true)
							// console.log('regex')

						} else {
							if(data.question.format === 'slider') {
								// console.log('else slider')
								AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide = conditionedQuestion.conditional_answer == conditionedQuestion.conditional_question_id.options.hint['fr'][data.inputValue].label ? false : true
							} else {
								// console.log('else else')

								AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide = conditionedQuestion.conditional_answer == data.inputValue ? false : true
							}

							// console.log(conditionedQuestion)
							// console.log(conditionedQuestion.name + ' ')
							// console.log(conditionedQuestion.conditional_answer) //Moyennes
							// console.log(data.inputValue) // 3
							//
							// console.log(conditionedQuestion.conditional_answer == data.inputValue ? false : true)

						}
						// console.log(conditionedQuestion.conditional_answer)
						// console.log(data)
					}
					EventBus.$emit('refreshQuestions')

					if (!data.show) {
						delete AppStore.rating.questions_answers[data.id]
						//TODO this.$refs['qi' + index][0].sliderValue = 0
					}
				})
			}
			// console.log('AppStore.rating')
			// console.log(AppStore.rating)
		},

		setAnswer(data) {
			if (_.includes(data.question.short_name.toLowerCase(), 'nps') && (data.inputValue === undefined || data.inputValue === null)) {
				delete AppStore.rating.questions_answers[data.question.id]
			} else {
				// console.log(data.inputValue)
				this.$set(AppStore.rating.questions_answers, data.question.id, {
					question_id: data.question.id,
					content: data.inputValue
				})
			}
		},

		setHiddenQuestions() {
			_.forEach(AppStore.hiddenQuestions, (hiddenQuestion, index) => {
				if (hiddenQuestion.short_name === param[0]) {
					this.$set(AppStore.rating.questions_answers, hiddenQuestion.id, {
						question_id: hiddenQuestion.id,
						content: param[1]
					})
				}
			});
		}
	}
};
</script>
<style>
.pointer {
	cursor: pointer;
}

.revert-btn {
	background-color: white;
}

#comment-help:before {
	opacity: 0.1
}

.timeline-row {
	background-color: white;
	border-radius: 8px;
}

#home-title {
	text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.carrousel-stars button, .timeline-row button {
	padding: 0px !important;
}

#offline-icon {
	color: red;
}

#questions-page .v-input input {
	max-height: 56px;
	height: 56px;
	font-size: 1.2rem;
}

#questions-page .v-messages__message {
	font-size: 0.9rem;
}

#close-cgu::before {
	font-weight: bold;
}

#appreciation-page .v-chip:hover::before {
	opacity: 0 !important;
}

#app-bar-logo .v-image__image--cover {
	background-size: contain !important;
}

.v-application .primary--text {
	color: inherit !important;
	caret-color: inherit !important;
}
</style>